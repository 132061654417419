<template>
  <div class="container pb-12">
    <!--router-link to="/registrodemusicas/cadastrar">
    <img src="../../assets/RegistrarMusicas.png" class="registrar-musicas" alt="Registrar Musicas" >
    </router-link-->
    <BannerMinterMusic :user="user"/>
    <BannerImportanciaRegistro/>
    <BannerComoRegistrar :user="user"/>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BannerComoRegistrar from '../../components/RegistroMusica/BannerComoRegistrar.vue'
import BannerImportanciaRegistro from '../../components/RegistroMusica/BannerImportanciaRegistro.vue'
import BannerMinterMusic from '../../components/RegistroMusica/BannerMinterMusic.vue'
export default {
  name: 'RegistroMusicas',
    components: {
      BannerMinterMusic,
      BannerImportanciaRegistro,
      BannerComoRegistrar,
    },
    computed: {
      ...mapGetters([
        'user'
      ]),
    },
    methods: {
      ...mapActions([
          'fetchUser'
        ]),
    },
    created() {
      this.fetchUser()
    },
}
</script>

<style>

</style>