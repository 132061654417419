<template>
  <div class="banner-1 mt-3">
    <div class="grid gap-6 grid-cols-1 mb-10" >
        <div class="grid grid-cols-1 primeiro-bloco" >
            <div>
                <p class="minter-title">Como registrar</p>
                <p class="minter-title1">Minhas Músicas?</p>
            </div>
            <div class="mt-10">
                <p class="minter-title">Primeiro passo clique em</p>
                <p class="minter-title2">registre sua música.</p>
            </div>
        </div>
    </div>
    <div class="grid gap-6 grid-cols-1 md:grid-cols-2 banner-2 ">
            <p>Preencha os dados solicitados, faça o upload dos arquivos solicitados para comprovar a autoria e leia os “termos de uso”. Em seguida, clique em “continuar” e faça o pagamento.</p>
            <p>Dica: Caso você não tenha um cadastro na plataforma Música Protegida, antes do pagamento será necessário fazê-lo. 
                Faça o cadastro e ative sua conta no seu e-mail, em seguida faça o pagamento via pix.
            </p>
    </div>
    <div class="flex justify-center p-4">
        <button class="btn btn-block btn-registrar" @click="prosseguir()">REGISTRE SUA MÚSICA</button>
    </div>
  </div>
</template>

<script>
import router from '@/router';
export default {
    name: 'BannerComoRegistrar',
    props: {
        user: null,
    },
    methods: {
      prosseguir() {
        if(this.user) {
          router.push({ name: 'RegistrarMusica' })
        } else {
          this.$router.push({ name: 'Login', query: { redirect: '/registrodemusicas/cadastrar' } });
        }
      },
    }
}
</script>

<style scoped>
.btn-registrar {
  color: black;
  width: 20em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1em;
  margin-top: 2em;
  border-radius: 10px !important;
}

.banner-1 {
  background-image: url('../../../public/assets/img/registrar_musica/banner_3.png');
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Inter';
  display: table;
  width: 100%;
  height: 100%;
  
  
}

.primeiro-bloco {
    margin: 5em 5em 0 5em;
}

.minter-title {
    color: #D4D3D2;
    font-size: 36px;
    line-height: 30px;
}

.minter-title1 {
    font-size: 58px;
    line-height: 45px;
    background: linear-gradient(98.57deg, #F428D0 15.9%, #17BCEB 78.95%);
    font-weight: 600;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    width: fit-content;
}

.minter-title2 {
    color: #07C6ED;
    font-size: 36px;
    line-height: 30px;
}

.banner-2 {
    color: #BFBEBF;
    font-size: 27px;
    letter-spacing: -0.02em;
    margin: 0 3em;
}

@media (max-width:700px) {
    .primeiro-bloco {
        margin: 1em 0 0 1em;
    }

    .banner-1 {
        height: 100%;
    }

    .banner-2 {
        font-size: 15px;
        margin: 1em 2em 0 1em;
        
    }
    
    .banner-2 p {
        color: #FFF;
    }

    .btn-registrar {
        width: 100%;
        margin: 0;
        
    }

    .minter-title {
        font-size: 20px;
    }

    .minter-title1 {
        font-size: 30px;
        line-height: 25px;
    }

    .minter-title2{
        font-size: 20px;
        font-weight: bolder;
        line-height: 20px;
    }
}
</style>