<template>
    <div class="banner-1 mb-3">
      <div class="grid gap-6 grid-cols-1 mb-6 md:grid-cols-2" >

      <div class="grid grid-cols-1 banner-2" >

          <p class="minter-title">ICHELLO</p>
          <p class="minter-title1" >MINTER</p>
          <p class="minter-title2" >MUSIC</p>
          <p class="minter-title3">BLOCKCHAIN PROTECT</p>


          <div class="text-music-mint" >
            <p>Use a tecnologia blochchain. Registre suas músicas seguindo os passos abaixo e garanta seu certificado de direitos autorais.</p>
          </div>

          <div class="text-music-mint-price" >
            <p>R$<span>9,90</span> </p>
          </div>

          <div class="">
            <button class="btn btn-block btn-registrar" @click="prosseguir()">REGISTRE SUA MÚSICA</button>
          </div>
      </div>      
      </div>
    </div>
</template>

<script>
import router from '@/router';
export default {
    name: "BannerMinterMusic",
    props: {
      user: null,
    },
    methods: {
      prosseguir() {
        if(this.user) {
          router.push({ name: 'RegistrarMusica' })
        } else {
          this.$router.push({ name: 'Login', query: { redirect: '/registrodemusicas/cadastrar' } });
        }
      },
    },
}
</script>

<style scoped>

.registrar-musicas {
    width: 100%;
    cursor: pointer;
}

.banner-1 {
  background-image: url('../../../public/assets/img/registrar_musica/banner_1.png');
  background-color: #FFF;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Inter';
  width: 100%;
  height: 100%;
  display: table;

}

.banner-2 {
  margin: 1em;
  
}

.minter-title {
  margin-left: 0.1em;
  font-weight: 600;
  font-size: 36px;
  letter-spacing: -0.02em;
  color: #BFBEBF;
}

.minter-title1 {
  font-weight: 600;
  font-size: 86px;
  letter-spacing: -0.02em;
  line-height: 60px;
}

.minter-title2 {
  font-weight: 600;
  font-size: 105px;
  letter-spacing: -0.02em;
  line-height: 75px;
}
.minter-title3 {
  margin-left: 0.2em;
  font-size: 30px;
  letter-spacing: -0.02em;
  line-height: 25px;
}

.minter-title1, .minter-title2, .minter-title3, .text-music-mint-price {
  background: linear-gradient(98.57deg, #F428D0 15.9%, #17BCEB 78.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  width: fit-content;
}

.text-music-mint {
  color: #BFBEBF;
  font-size: 1.5em;
  font-weight: 500;
  margin-top: 0.8em;  
  width: fit-content;
}

.text-music-mint-price {
  font-style: normal;
  font-weight: 600;
  font-size: 3em;
  margin-top: 0.5em;
}

.text-music-mint-price span {
  font-size: 2em;
  line-height: 1em;
}

.btn-registrar {
  color: black;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1em;
  margin-top: 2em;
  border-radius: 10px !important;
}

@media (max-width:500px) {
  .banner-1 {
    background-position: -170px 0;
    height: 3em !important;
    padding-top: 1em;
  }

  .text-music-mint p{
    font-size: 15px;
    width: 12em;
  }

  .text-music-mint-price {
    /* margin-bottom: 4em; */
    margin-bottom: 0.3em;
}

  .banner-2 {

  }

  .btn-registrar {
  margin-top: 0;
  }

  .minter-title {
    line-height: 18px; 
    font-size: 18px;
  }

  .minter-title1 {
    line-height: 25px; 
    font-size: 36px;
  }

  .minter-title2 {
    line-height: 30px; 
    font-size: 44px;
    
  }

 .minter-title3 {
   line-height: 13px !important; 
   font-size: 12px !important;
   letter-spacing: -0.01em;
  }

  .text-music-mint-price {
    font-size: 1.5em;
    margin: 0.5em 0 0.8em 0;

  }

  .text-music-mint-price span {
  font-size: 1.8em;
  line-height: 1em;
}

}

@media (min-width:501px) and (max-width:1024px) {
    .banner-1 {
    background-position: -300px 0;
    height: 40em;
  }

  .text-music-mint p{
    color: #FFF;
    width: 12em;
  }

  .banner-2 {
    /* margin: 0 1em; */
    margin: 5em;
  }

  .minter-title1 {
    line-height: 60px;
  }

  .minter-title2 {
    line-height: 75px;
  }

 .minter-title3 {
  font-size: 28px;
  letter-spacing: -0.01em;
  line-height: 25px; 
  }

  .btn-registrar {
    width: 25em;
  }
}

@media (min-width: 1025px) {
  .banner-2 {
    margin: 5em;
  
  }
}

</style>
